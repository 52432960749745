<template>
  <v-card flat class="px-1 mt-1">
    <v-card-text class="pa-0">
      <h3 class="pa-1 pl-2">{{$t('message.projects.project')}}: <router-link :to="`/projects/${customModuleObj.projectId}`">{{ projectFullName }}</router-link></h3>
    </v-card-text>
    <scroll-list v-if="renderScrollList" :key="reInit" id="customModuleScrollList" :payload="scrollListPayload"></scroll-list>
  </v-card>
</template>
<script>
import { hostAppApi } from '@/plugins/axios_settings'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      listOfStatus: [this.$t('message.deviation.registered'), this.$t('message.deviation.process'), this.$t('message.deviation.closed')],
      scrollListPayload: {
        width: 60,
        module: '',
        moduleTitle: '', // 'message.customModule.customModule',
        addBtnTo: '',
        pagination: {},
        totalCount: 0,
        perPage: 6,
        loading: false,
        editHandler: null,
        actionSize: '1', // for delete action
        list: [],
        itemHeaders: [
          // this.$t('message.deviation.description'),
          // this.$t('Status')
          'message.deviation.description',
          'Status'
        ],
        items: [{
          value: 'description',
          class: 'pa-0 pa-1 text-justify',
          cols: '8',
          spanClass: 'body pl-2',
          isParagraph: true
        }, {
          value: '',
          cols: '3',
          class: 'pa-0',
          rowClass: 'mt-1',
          subItems: [{
            value: 'is_completed',
            class: 'pa-0',
            cols: '12',
            spanClass: 'body'
          }]
        }],
        storeIndex: 'PROJECT_CUSTOM_MODULE_INDEX',
        isLivePagination: true,
        hasDelete: true,
        hasNoNext: true,
        isCustomModules: true,
        page: -1,
        previousSearch: '',
        recordsPerPage: 15
      },
      MODULE: 'custommodule',
      renderScrollList: false,
      listMaxCallLoaded: false,
      loading: false,
      reInit: 0,
      pagination: !this.$formatter.isEmptyObject(this.$store.state.common.PROJECT_CUSTOM_MODULE_INDEX) ? this.$store.state.common.PROJECT_CUSTOM_MODULE_INDEX : { itemsPerPage: 6, page: 1 },
      customModuleObj: {
        moduleId: parseInt(this.$route.params.module_id),
        projectId: parseInt(this.$route.params.project_id)
      },
      hostRef: this.$cookie.get('AxentivPwa_host-ref'),
      hostUserId: this.$cookie.get(process.env.VUE_APP_HOST_USER) ? parseInt(this.$cookie.get(process.env.VUE_APP_HOST_USER)) : 0,
      projectFullName: ''
    }
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId'])
  },
  components: {
    'scroll-list': () => import('@/components/VirtualScrollList.vue')
  },
  created () {
    if (this.$route.query && this.$route.query.name) this.scrollListPayload.moduleTitle = this.$route.query.name
    this.scrollListPayload.editHandler = (item) => this.$router.push(`/projects/${this.customModuleObj.projectId}/custom_modules/${this.customModuleObj.moduleId}/action/${item.id}?name=${this.scrollListPayload.moduleTitle}`)
    this.scrollListPayload.addBtnTo = `/projects/${this.customModuleObj.projectId}/custom_modules/${this.customModuleObj.moduleId}/action?name=${this.scrollListPayload.moduleTitle}`
    // this.getProject(parseInt(this.$route.params.project_id))
  },
  mounted () {
    this.getListHandler()
    const text = 'message.common.deleteSuccess'
    this.$eventBus.$on('deleteItems', (data) => {
      if (data.module === this.MODULE) {
        const payload = { url: `custom-modules/${data.id}?moduleId=${this.customModuleObj.moduleId}&project_id=${this.customModuleObj.projectId}&user_id=${this.hostUserId}`, isSingle: true, module: this.MODULE, fromHostApi: true }
        this.$store.commit('showDeleteDialog', payload)
      }
    })
    this.$eventBus.$on('deleteSuccess', (data) => {
      if (data === this.MODULE) {
        this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
        this.$store.commit('hideDialog')
        // this.getListHandler(this.pagination)
        this.listMaxCallLoaded = false
        this.scrollListPayload.page = -1
        this.scrollListPayload.list = []
        this.getListHandler()
      }
    })
    // Load list on page
    this.$eventBus.$on('loadList', (data) => {
      this.getListHandler(data)
    })
  },
  methods: {
    getListHandler (search) {
      // const pagination = paginationObj || this.pagination
      // this.payload.pagination.page = pagination.page
      // this.payload.loading = true
      // this.payload.module = this.MODULE
      // const search = pagination.search ? pagination.search : ''
      // const pageVal = pagination.page // search ? 1 : pagination.page
      // const model = {
      //   start: ((pageVal - 1) * this.pagination.itemsPerPage),
      //   length: this.pagination.itemsPerPage,
      //   search: search
      // }
      if (search && (search !== this.scrollListPayload.previousSearch)) {
        this.scrollListPayload = { ...this.scrollListPayload, ...{ list: [], page: -1, previousSearch: search, totalCount: 0 } }
        this.listMaxCallLoaded = false
      }
      if (!search && this.scrollListPayload.previousSearch) {
        this.scrollListPayload = { ...this.scrollListPayload, ...{ list: [], page: -1, previousSearch: '', totalCount: 0 } }
        this.listMaxCallLoaded = false
      }
      if (this.listMaxCallLoaded) return
      this.scrollListPayload.loading = true
      this.scrollListPayload.module = this.MODULE
      this.scrollListPayload.page++
      const model = {
        start: (this.scrollListPayload.page * this.scrollListPayload.recordsPerPage),
        length: this.scrollListPayload.recordsPerPage
      }
      let url = `https://${this.hostRef}/api/custom-modules?moduleId=${this.customModuleObj.moduleId}&project_id=${this.customModuleObj.projectId}&user_id=${this.hostUserId}&only_items=1&start=${model.start}&length=${model.length}`
      if (search) url += `&search=${search}`
      hostAppApi.get(url)
        .then(response => {
          let { data, recordsFiltered, projectFullName } = response.data
          this.scrollListPayload.total = recordsFiltered
          const transformData = {
            date: { list: ['deadline'], from: 'YYYY-MM-DD', to: 'DD.MM.YYYY' }
          }
          data = this.$formatter.formatListModel(data, transformData)
          data = data.map(value => { return { ...value, status: this.listOfStatus[value.status - 1] } })
          this.scrollListPayload.list = [...this.scrollListPayload.list, ...data]
          if (data.length) this.scrollListPayload.totalCount = recordsFiltered
          if (this.scrollListPayload.totalCount === this.scrollListPayload.list.length) this.listMaxCallLoaded = true
          this.projectFullName = projectFullName
          // let { data, recordsFiltered, projectFullName } = response.data
          // this.payload.total = recordsFiltered
          // const transformData = {
          //   date: { list: ['deadline'], from: 'YYYY-MM-DD', to: 'DD.MM.YYYY' }
          // }
          // data = this.$formatter.formatListModel(data, transformData)
          // const totalCount = Math.ceil(recordsFiltered / pagination.itemsPerPage)
          // if (pagination.page === totalCount) this.payload.hasNoNext = true
          // else this.payload.hasNoNext = false
          // this.payload.list = data
          // if (this.reInit === 0) this.reInit++
        }).finally(() => {
          /* if (paginationObj) {
            const model = { type: 'BUILDING_INDEX', data: { page: paginationObj.page, itemsPerPage: paginationObj.itemsPerPage } }
            this.$store.commit('rememberOrder', model)
          } */
          this.scrollListPayload.loading = false
          if (this.scrollListPayload.page === 0) this.renderScrollList = true
        })
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('deleteSuccess')
    this.$eventBus.$off('deleteItems')
    this.$eventBus.$off('loadList')
  }
}
</script>
